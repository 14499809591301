import React from 'react';
import singlecbg from '../../assets/images/single-c-bg.png'
import { StaticQuery, graphql } from "gatsby"

const Banner = () => (
    <StaticQuery
         query={graphql`
            query {
               allWpPage(filter: {slug: {eq: "quote"}}) {
                  edges {
                    node {
                      slug
                      TeamlanceQuote {
                        quotes {
                          description
                          emailLabel
                          emailPlaceholder
                          nameLabel
                          namePlaceholder
                          projectDescriptionLabel
                          projectDescriptionPlaceholder
                          projectLabel
                          projectPlacehoder
                          title
                          quoteSteps {
                            buttonText
                            description
                            heading
                            iconSvg
                            stepDescription
                            stepOptions {
                              description
                              iconSvg
                              title
                            }
                            stepTitle
                            title
                          }
                        }
                      }
                    }
                  }
                }
            }
         `}
         render={data => {
             return(
                data && data.allWpPage &&
                data.allWpPage.edges &&
                data.allWpPage.edges[0] &&
                data.allWpPage.edges[0].node &&
                data.allWpPage.edges[0].node.TeamlanceQuote &&
                data.allWpPage.edges[0].node.TeamlanceQuote.quotes &&

                <section class="quote-bn">
                        <div class="bn-bg quote-bg">
                            <img src={singlecbg} alt="case study" />
                        </div>
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12  d-flex flex-column sectiontop-pd  ">
                                    <div class=" quote-banner text-center case-heading my-auto ml-0 ">
                                        <h3 class="">{data.allWpPage.edges[0].node.TeamlanceQuote.quotes.title}</h3>
                                            
                                        <p>{data.allWpPage.edges[0].node.TeamlanceQuote.quotes.description}</p>
                                </div>
                            </div> 
                        </div>
                        </div> 
                 </section>
             )
         }}
      />
    
)

export default Banner;