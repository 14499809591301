import React from 'react';
import { StaticQuery, graphql } from "gatsby"


const Step1 = (props) => (
   <StaticQuery
         query={graphql`
            query {
               allWpPage(filter: {slug: {eq: "quote"}}) {
                  edges {
                    node {
                      slug
                      TeamlanceQuote {
                        quotes {
                          description
                          emailLabel
                          emailPlaceholder
                          nameLabel
                          namePlaceholder
                          projectDescriptionLabel
                          projectDescriptionPlaceholder
                          projectLabel
                          projectPlacehoder
                          title
                          quoteSteps {
                            buttonText
                            description
                            heading
                            iconSvg
                            stepDescription
                            stepOptions {
                              description
                              iconSvg
                              title
                            }
                            stepTitle
                            title
                          }
                        }
                      }
                    }
                  }
                }
            }
         `}
         render={data => {
            return(
                data && data.allWpPage &&
                data.allWpPage.edges &&
                data.allWpPage.edges[0] &&
                data.allWpPage.edges[0].node &&
                data.allWpPage.edges[0].node.TeamlanceQuote &&
                data.allWpPage.edges[0].node.TeamlanceQuote.quotes &&
                data.allWpPage.edges[0].node.TeamlanceQuote.quotes.quoteSteps&&
                data.allWpPage.edges[0].node.TeamlanceQuote.quotes.quoteSteps.length>0&&
              <section className="share-sec">
               <div className="share-top">
                  <svg width="366" height="185" viewBox="0 0 366 185" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M51.8221 167.583L-35.3348 87.7328L-40 34.2603L358.287 64.1671L83.1531 173.347C72.4676 177.588 60.2986 175.349 51.8221 167.583Z" fill="#D8F4FF"/>
                  </svg>
               </div>
               <div className="container my-5">
                  <div className="row">
                     <div className="desktop-hidden w-100">
                        <div className="d-flex m-steps col-12 justify-content-center align-items-center  mb-4">
                           <div className="mobile-steps ">
                              <p className="mb-0">1/6</p>
                              <span dangerouslySetInnerHTML={{__html:data.allWpPage.edges[0].node.TeamlanceQuote.quotes.quoteSteps[0].iconSvg}}></span>
                           </div>
                           <div className="quote-labels mr-4">
                              <h6 className="mb-0">{data.allWpPage.edges[0].node.TeamlanceQuote.quotes.quoteSteps[0].title}</h6>
                              <p className="mb-0">{data.allWpPage.edges[0].node.TeamlanceQuote.quotes.quoteSteps[0].description}</p>
                           </div>
                        </div>
                     </div>
                     <div className="col-md-5 col-lg-4 mobile-hidden leftQuoteMenu">
                        <div className="quote-items d-flex justify-content-end align-items-center">
                           <div className="quote-labels mr-4">
                              <h6 className="mb-0">{data.allWpPage.edges[0].node.TeamlanceQuote.quotes.quoteSteps[0].title}</h6>
                              <p className="mb-0">{data.allWpPage.edges[0].node.TeamlanceQuote.quotes.quoteSteps[0].description}</p>
                           </div>
                           <span dangerouslySetInnerHTML={{__html:data.allWpPage.edges[0].node.TeamlanceQuote.quotes.quoteSteps[0].iconSvg}} className="quote-icons quote-active "></span>
                           <div  className="circle-icon active">
                              <div className="vx">-</div>
                           </div>
                           <div className="quote-border"></div>
                        </div>
                        <div className="quote-items d-flex justify-content-end align-items-center">
                           <div className="quote-labels mr-4">
                              <h6 className="mb-0">{data.allWpPage.edges[0].node.TeamlanceQuote.quotes.quoteSteps[1].title}</h6>
                              <p className="mb-0">{data.allWpPage.edges[0].node.TeamlanceQuote.quotes.quoteSteps[1].description}</p>
                           </div>
                           <span dangerouslySetInnerHTML={{__html:data.allWpPage.edges[0].node.TeamlanceQuote.quotes.quoteSteps[1].iconSvg}} className="quote-icons"></span>
                           <div className="circle-icon">
                              <div className="vx">-</div>
                           </div>
                           <div className="quote-border"></div>
                        </div>
                        <div className="quote-items d-flex justify-content-end align-items-center">
                           <div className="quote-labels mr-4">
                              <h6 className="mb-0">{data.allWpPage.edges[0].node.TeamlanceQuote.quotes.quoteSteps[2].title}</h6>
                              <p className="mb-0">{data.allWpPage.edges[0].node.TeamlanceQuote.quotes.quoteSteps[2].description}</p>
                           </div>
                           <span dangerouslySetInnerHTML={{__html:data.allWpPage.edges[0].node.TeamlanceQuote.quotes.quoteSteps[2].iconSvg}} className="quote-icons"></span>
                           <div className="circle-icon">
                              <div className="vx">-</div>
                           </div>
                           <div className="quote-border"></div>
                        </div>
                        <div className="quote-items d-flex justify-content-end align-items-center">
                           <div className="quote-labels mr-4">
                           <h6 className="mb-0">{data.allWpPage.edges[0].node.TeamlanceQuote.quotes.quoteSteps[3].title}</h6>
                              <p className="mb-0">{data.allWpPage.edges[0].node.TeamlanceQuote.quotes.quoteSteps[3].description}</p>
                           </div>
                           <span dangerouslySetInnerHTML={{__html:data.allWpPage.edges[0].node.TeamlanceQuote.quotes.quoteSteps[3].iconSvg}} className="quote-icons "></span>
                           <div className="circle-icon">
                              <div className="vx">-</div>
                           </div>
                           <div className="quote-border"></div>
                        </div>
                        <div className="quote-items d-flex justify-content-end align-items-center">
                           <div className="quote-labels mr-4">
                              <h6 className="mb-0">{data.allWpPage.edges[0].node.TeamlanceQuote.quotes.quoteSteps[4].title}</h6>
                              <p className="mb-0">{data.allWpPage.edges[0].node.TeamlanceQuote.quotes.quoteSteps[4].description}</p>
                           </div>
                           <span dangerouslySetInnerHTML={{__html:data.allWpPage.edges[0].node.TeamlanceQuote.quotes.quoteSteps[4].iconSvg}} className="quote-icons"></span>
                           <div className="circle-icon">
                              <div className="vx">-</div>
                           </div>
                           <div className="quote-border"></div>
                        </div>
                        <div className="quote-items d-flex justify-content-end align-items-center">
                           <div className="quote-labels mr-4">
                              <h6 className="mb-0">{data.allWpPage.edges[0].node.TeamlanceQuote.quotes.quoteSteps[5].title}</h6>
                              <p className="mb-0">{data.allWpPage.edges[0].node.TeamlanceQuote.quotes.quoteSteps[5].description}</p>
                           </div>
                           <span dangerouslySetInnerHTML={{__html:data.allWpPage.edges[0].node.TeamlanceQuote.quotes.quoteSteps[5].iconSvg}} className="quote-icons"></span>
                           <div className="circle-icon">
                              <div className="vx">-</div>
                           </div>
                           <div className="quote-border"></div>
                        </div>
                     </div>
                     <div className="col-md-7 col-lg-7 rightQuoteMenu">
                        <div className="items-iner">
                           <p className="steps mb-0 mobile-hidden">{data.allWpPage.edges[0].node.TeamlanceQuote.quotes.quoteSteps[0].stepTitle}</p>
                           <h4>Let’s start with your name</h4>
                           <p className="mb-4">{data.allWpPage.edges[0].node.TeamlanceQuote.quotes.quoteSteps[0].stepDescription}</p>
                           <hr />
                           <form className="mt-4 quote-form  ">
                              <div className="form-group float-label-control">
                                 <label htmlFor="name">Enter your name</label>
                                 <input id="name" type="text" className="form-control" placeholder="Enter full name"/>
                              </div>
                              <div className="text-right start-btn btn-spacing">
                                 <button onClick={()=>{props.nextStep()}} data-abc="true" className="btn btn-default waves-effect waves-light next-step" >{data.allWpPage.edges[0].node.TeamlanceQuote.quotes.quoteSteps[0].buttonText}</button>
                              </div>
                           </form>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="share-bottom quote-svg">
                  <svg width="488" height="191" viewBox="0 0 488 191" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M505.679 145.456L-1141.05 391.43L-1135.95 425.56L365.13 0.63037L491.67 51.6707L505.679 145.456Z" fill="#F4F8FF"/>
                  </svg>
               </div>
             </section>
            )
         }}
      />
  
)

export default Step1;