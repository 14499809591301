import React,{useState} from 'react';
import Layout from "../components/layout";
import SEO from "../components/seo";
import Banner from "../components/quote/banner";
import Step1 from "../components/quote/step1";
import Step2 from "../components/quote/step2";
import Step3 from "../components/quote/step3";
import Step4 from "../components/quote/step4";
import Step5 from "../components/quote/step5";
import Step6 from "../components/quote/step6";
import Testimonial from "../components/home/testimonial"

const Quote = (props) => {
    const [step, setStep] = useState(1)
    const nextStep = () => {
        console.log("Hello World");
        if(step<6){
            setStep(step+1)
        }
        console.log("Step", step);
    }

    const previousStep = () => {
        if(step>1){
            setStep(step-1);
        }
    }

     return(
        <Layout {...props}>
            <SEO title="Quote"/>
            <div class="col-12 wrapper main-bggrey p-0">
                <div class="col-12 inner-page-content home-inner px-0">
                    <Banner/>
                </div>
            </div>
            {step==1?<Step1 previousStep={()=>previousStep()} nextStep={()=>{nextStep()}}/>:null}
            {step==2?<Step2 previousStep={()=>previousStep()} nextStep={()=>{nextStep()}}/>:null}
            {step==3?<Step3 previousStep={()=>previousStep()} nextStep={()=>{nextStep()}}/>:null}
            {step==4?<Step4 previousStep={()=>previousStep()} nextStep={()=>{nextStep()}}/>:null}
            {step==5?<Step5 previousStep={()=>previousStep()} nextStep={()=>{nextStep()}}/>:null}
            {step==6?<Step6 previousStep={()=>previousStep()} nextStep={()=>{nextStep()}}/>:null}
            <Testimonial/>
        </Layout>  
     )
}

export default Quote;